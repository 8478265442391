.checkbox-columns .checkbox {
  display: block;
  margin: 0 0 10px 0;
}

fieldset {
  border: 0;
}

legend {
  background: #fff;
  font-weight: bold;
  padding: 0 0.5rem;
}

/* stylelint-disable-next-line selector-no-qualifying-type */
label.required:after {
  color: $danger;
  content: ' *';
  display: inline;
}

.control--checkbox {
  margin-top: 15px;
}

/* stylelint-disable-next-line selector-no-qualifying-type */
p.small {
  width: 50px;
  margin-right: 5px;
}

input[type=radio] {
  margin-right: 10px;
}

input[type=file] {
  padding: 5px;
}

.form__hidden-fields {
  display: none;

  .no-js & {
    display: block;
  }
}

.table td {
  vertical-align: middle;
}
