/* Tag */
.skills .tag {
  margin-bottom: 8px;
}

.tag .icon,
.level .tag {
  margin-right: 8px;
}

.tag {
  > a {
    @extend .delete;
    @extend .is-small;
    color: transparent;
  }
}
