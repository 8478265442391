.button-add {
  background: transparent;
  border: 0;
  cursor: pointer;
  outline: none;
}

.button-login {
  display: inline-block;
  margin-top: 20px;
  padding: 4px 10px;
}

.button-icon-login {
  margin: 5px 5px 0 0;
}

.button-icon {
  margin-right: 5px;
}

.button .icon.no-margin {
  margin: 0;
}

.button-link {
  color: #fff !important;
}
