.invoice-title {
  padding-left: 10px;
}

.invoice-button {
  margin-left: 10px;
}

.invoices-total {
  margin-top: 1em;
}
