.project-tag {
  margin: 2px 0;
  border-radius: 4px;
  position: absolute;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  height: 24px;
  padding: 4px 5px;
  white-space: nowrap;
  font-weight: bold;
  text-align: center;
  color: #fff;
}

.date-tag {
  background-color: $bluegrey;
  border-radius: 4px;
  color: #fff;
}

.line {
  position: relative;
  height: 28px;
}

.project-tag-part-time {
  color: $grey-darker;
}

.project-tag--ongoing {
  background-color: #66bb6a;
}

.project-tag--ongoing.project-tag-part-time {
  background: repeating-linear-gradient(45deg, #d5f5c5, #d5f5c5 5px, #e3f5da 5px, #e3f5da 10px);
}

.project-tag--possible {
  background-color: #2196f3;
}

.project-tag--possible.project-tag-part-time {
  background: repeating-linear-gradient(45deg, #c1ddf3, #c1ddf3 5px, #dce9f3 5px, #dce9f3 10px);
}

.project-tag--vacation {
  background-color: #f50057;
}

.project-tag--extra {
  background-color: #ffc107;
}

.project-tag--extra.project-tag-part-time {
  background: repeating-linear-gradient(45deg, #ffe8a5, #ffe8a5 5px, #fff4d5 5px, #fff4d5 10px);
}

.project-tag--internal {
  background-color: #a6a6a6;
}

.project-tag--internal.project-tag-part-time {
  background: repeating-linear-gradient(45deg, #c9c9c9, #c9c9c9 5px, #cfcfcf 5px, #cfcfcf 10px);
}

.project-tag--busy {
  background-color: #cb4b16;
}

.project-tag--busy.project-tag-part-time {
  background: repeating-linear-gradient(45deg, rgb(203, 139, 116), #cb8b74 5px, #b07a12 5px, #b07a12 10px);
}

.project-tag-width-1 {
  width: 20%;
}

.project-tag-width-2 {
  width: 40%;
}

.project-tag-width-3 {
  width: 60%;
}

.project-tag-width-4 {
  width: 80%;
}

.project-tag-width-5 {
  width: 100%;
}

.project-tag-start-1 {
  left: 0;
}

.project-tag-start-2 {
  left: 20%;
}

.project-tag-start-3 {
  left: 40%;
}

.project-tag-start-4 {
  left: 60%;
}

.project-tag-start-5 {
  left: 80%;
}
