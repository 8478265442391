@import 'node_modules/tether/src/css/tether';
@import 'node_modules/tether-drop/dist/css/drop-theme-basic';
@import 'node_modules/tether-tooltip/dist/css/tooltip-theme-arrows';

/*
  HubSpot tooltip style override to prevent displaying long,
  single-line tooltips and make the text smaller.
 */

.tooltip-element.tooltip-theme-arrows {
  max-width: 280px;
}

.tooltip .tooltip-content {
  font-size: 14px !important;
}
