/* Utilities */

.u-nowrap {
  white-space: nowrap;
}

.u-vertical-center {
  align-items: center;
}

.u-full-width {
  width: 100%;
}

.u-width-30 {
  width: 30%;
}

.u-margin-t-10 {
  margin-top: 10px;
}

.u-margin-t-1 {
  margin-top: 1px;
}

.u-margin-t-3 {
  margin-top: 3px;
}

.u-margin-l-10 {
  margin-left: 10px;
}

.u-margin-b-0 {
  margin-bottom: 0 !important;
}

.u-margin-b-10 {
  margin-bottom: 10px;
}

.u-margin-b-20 {
  margin-bottom: 20px;
}

.u-margin-r-20 {
  margin-right: 20px;
}

.u-margin-r-4 {
  margin-right: 4px;
}

@include mobile {
  .u-mobile-full {
    width: 100%;
  }
}

@include desktop-small {
  .icon.u-desktop-hidden {
    display: none;
  }
}

.u-no-border {
  border: 0 !important;
}

.u-no-padding {
  padding: 0 !important;
}
