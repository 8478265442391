/* Table */

.table.is-narrow {
  width: auto;
}

.table .vertical-middle {
  vertical-align: middle;
}

.table .no-padding-left {
  padding-left: 4px;
}

.table .border {
  border-right: 1px solid #dbdbdb;
  border-left: 1px solid #dbdbdb;
}

.table .sorting,
.table .sorting_asc,
.table .sorting_desc {
  background-repeat: no-repeat;
  background-position: center right;
}

.table .sorting {
  background-image: url("../assets/images/sort_both.png");
}

.table .sorting_asc {
  background-image: url("../assets/images/sort_asc.png");
}

.table .sorting_desc {
  background-image: url("../assets/images/sort_desc.png");
}
