.custom-radio {
  > input {
    position: absolute;
    opacity: 0;
    pointer-events: none;

    &:focus ~ .button {
      background: #ddd;
      color: inherit;
    }

    &:checked ~ .button {
      background: #555;
      color: #fff;
    }
  }
}

.custom-radio__container {
  display: flex;
}
