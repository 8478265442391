/* Availability */
.is-1 {
  width: 20%;
}

.is-2 {
  width: 40%;
}

.is-3 {
  width: 60%;
}

.is-4 {
  width: 80%;
}

.is-5 {
  width: 100%;
}

.cloud {
  float: right;
  color: $deeporange;
}

.availability-row {
  padding-right: 0 !important;
}

.current-week {
  background-color: #fff9c4;
}

.availability-item-wrapper {
  position: absolute;
  background: white;
  padding: 20px;
  width: 300px;
  border: 1px solid #dedede;
  box-shadow: 2px 2px 10px #dedede;
  margin-top: 9px;
  margin-left: 20px;
  z-index: 1;

  h2 {
    position: relative;
    margin-bottom: 10px;
  }

  .close-availability-item {
    position: absolute;
    top: -10px;
    right: -9px;
  }
}

.availability-nav {
  .button {
    font-weight: normal;
  }

  &:hover {
    background-color: inherit !important;
  }

  th {
    padding-bottom: 20px;
  }
}
