$mobile-width: 768px;
$desktop-small-width: 1200px;

@mixin mobile {
  @media (max-width: $mobile-width) {
    @content;
  }
}

@mixin desktop-small {
  @media (max-width: $desktop-small-width) and (min-width: $mobile-width) {
    @content;
  }
}
