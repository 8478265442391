.ui-helper-hidden-accessible {
  display: none;
}

.ui-autocomplete-input {
  @extend .input;
  width: 100% !important;
  margin: 0 0 10px;
}

.ui-autocomplete {
  background: #fff;
  box-shadow: 0 2px 4px #ccc;
  padding: 8px;
  max-width: 920px;
  border-radius: 3px;
}
